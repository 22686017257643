@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
*{
    font-family: "Poppins", serif
}

.bgimage {
    background-image: url("../../assets/banner/homebanner.png");
    height: 100vh;
    width: 100%;
    background-size: cover; /* Ensures the image covers the entire area */
    background-repeat: no-repeat; /* Prevents repeating */
    background-position: center; /* Centers the image */
    display: flex;
    justify-content: center;
    align-items: center;
}
.bgImageBox{
  width: 90%;
  height: 80vh;
  margin-top: 50px;

}
.headermintext{
    margin-top:120px;
    font-weight:800;
    color:#07559f;
}

.headertext{
    margin-top:-20px;
    font-weight:800;
    font-size:8em;
    color:#11baff;
    text-shadow:2px 2px 5px rgba(0, 0, 0, 0.5);
    -webkit-text-stroke:2px white;
}

.popBox{
    width:50%;
    height:60%;
    background:#1187ff;
    border-radius:20px;
    box-shadow:5px 5px 10px gray;
    box-sizing: border-box;
    color: #fff;

}

.heading {
    font-family: "Abril Fatface", serif;
    color: #fff;
    text-align: center;
    font-size: 3em;
  
    
}

.eventDate{
    font-family: "Abril Fatface", serif; 
    text-align: center;
    margin-top: 10px;
    color: #fff;
}

@media only screen and (max-width: 600px) {

    .bgimage {
        background-image: url("../../assets/banner/homebanner.png");
        height: 80vh;
        width:100%;
        background-size: cover;
        background-repeat: none;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    
    .popBox{
        width:90%;
        height:60%;
        background:#1187ff;
    border-radius:20px;
    box-shadow:5px 5px 10px gray;
    box-sizing: border-box;
    color: #fff;
    }

    
.heading {
    font-family: "Abril Fatface", serif;
    color: #fff;
    text-align: center;
    font-size: 2em;
  
    
}

    .headermintext{
        margin-top:120px;
        font-weight:800;
        color:#07559f;
        text-align: center;
    }
    
    .headertext{
        margin-top:-10px;
        font-weight:800;
        font-size:2em;
        color:#11baff;
        text-align: center;
        text-shadow:2px 2px 5px rgba(0, 0, 0, 0.5);
        -webkit-text-stroke:2px white;
    }

}
  